import { useRef, useContext, useState, useEffect } from 'react';
import styles from './AddVisitorsComponent.module.css'

import { GlobalContext } from "../../contexts/globalContext";
import { ProjectDetailsContext } from '../../contexts/projectDetailsContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import InputTextComponent from '../keyboard/InputTextComponent';
// import KeyboardComponent from '../keyboard/KeyboardComponent';
import PopupHeaderComponent from './common/PopupHeaderComponent';
import PopupBodyComponent from './common/PopupBodyComponent';
import PopupBaseComponent from './common/PopupBaseComponent';
import ClosePageButtonComponent from '../commons/ClosePageButtonComponent'
import RightBodyComponent from './common/RightBodyComponent';
import LeftBodyComponent from './common/LeftBodyComponent';

import membersAdd from '../../graphics/Popups/IconMembersAdd.png'
import FooterButtonsComponent from './common/FooterButtonsComponent';
import OutsideGlowComponent from './common/OutsideGlowComponent';


const AddVisitorsComponent = () => {

    const inputRefName = useRef()
    const keyboardRef = useRef()

    const [isOnFocusName, setIsOnFocusName] = useState(false)

    const { language, selectedProject, socketRef } = useContext(GlobalContext);
    const { setAddVisitors } = useContext(ProjectDetailsContext);

 

    function handleEmailAdd(){
        // socketRef.current.emit("ADD_GUESTS_TO_PROJECT", {projectId: selectedProject._id, emailString: emailsList}, () => {
        //     setAddVisitors()
        // })
        socketRef.current.emit("CREATE_USER_AND_ADD_TO_PROJECT", { 
            projectId: selectedProject._id, 
            userData: {
                "name": inputRefName.current.value, 
                "email": output,
                "role": "VISITOR",
            } 
        }, (callback) => {
            if(callback) console.log(callback)
            
        })
        setAddVisitors(false)
    }

    const [input, setInput] = useState('');
    const [output, setOutput] = useState('');

    const transformString = (str) => {
        return str.toLowerCase().replace(/\s+/g, '.');
    };

    useEffect(() => {setOutput(transformString(input))},[input])

    
    return (
        <PopupBaseComponent>
            <PopupHeaderComponent text={language.add_guests['Add Guest']}/>
            <PopupBodyComponent style={{width: 4251, height: 1633}}>
                <ClosePageButtonComponent setClose={() => setAddVisitors(false)} />
                <LeftBodyComponent image={membersAdd} title="Add member to project" description="Create and add a new member. You will automatically add this as a member in the organization, as well as a member to the project."/>
                <RightBodyComponent>
                    <div className={styles.title}>Name</div>
                    {isOnFocusName && <OutsideGlowComponent style={{top: 232}}/>}
                    <InputTextComponent ref={inputRefName} onTextChanged={() => setInput(inputRefName.current.value)} keyboardRef={keyboardRef} value="" placeHolder="Enter name here" styleType='POPUP' width= "1560px" fontSize="40px" setIsOnFocus={setIsOnFocusName}/>
                    {!isOnFocusName && <FontAwesomeIcon className={styles.iconEdit} icon={byPrefixAndName.fal['pen']} />}
                    <div className={styles.title2}>Username: {output}</div>
                    
                    <FooterButtonsComponent lightText={"Add"} lightClick={handleEmailAdd} darkClick={setAddVisitors}/>
                {/* <KeyboardComponent visible ref={keyboardRef} x="330" y="250" width="750" height="450" s="1.8"/> */}
                </RightBodyComponent>
            </PopupBodyComponent>
            
        </PopupBaseComponent>
    );

}

export default AddVisitorsComponent;