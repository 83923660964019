export function GetMaxZIndex() {
    return Math.max(
        ...Array.from(document.querySelectorAll('body *'), el =>
            parseFloat(window.getComputedStyle(el).zIndex),
        ).filter(zIndex => !Number.isNaN(zIndex)),
        0,
    );
}


export function  sortProjects(value, list, upcomingConferences){
    if(value === "1"){
        let alphabeticalProjectsList = list.sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        });
        return alphabeticalProjectsList
    } else if(value === "2"){
        let alphabeticalProjectsList = list.sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            if (nameA < nameB) return 1;
            if (nameA > nameB) return -1;
            return 0;
        });
        return alphabeticalProjectsList
    } else if(value === "3"){
        let sortedUpcomingConf = upcomingConferences.sort((a, b) => new Date(a.scheduleDate) - new Date(b.scheduleDate));
        let projectsWithUpcomingConference = []
        let projectsWithoutUpcomingConference = []
        for(let i = 0; i < list.length; i++){
            if(upcomingConferences.some(conference => conference.project === list[i]._id)){
                projectsWithUpcomingConference.push(list[i])
            } else {
                projectsWithoutUpcomingConference.push(list[i])
            }
        }
        const conferenceIndexMap = new Map();
        sortedUpcomingConf.forEach((conference, index) => {
            conferenceIndexMap.set(conference.project, index);
        });
        projectsWithUpcomingConference.sort((a, b) => {
            const indexA = conferenceIndexMap.get(a._id);
            const indexB = conferenceIndexMap.get(b._id);
            return indexA - indexB;
        })
        let upcomingProjects = [...projectsWithUpcomingConference, ...projectsWithoutUpcomingConference]
        return upcomingProjects
    } else if(value === "4"){
        let sortedUpcomingConf = upcomingConferences.sort((a, b) => new Date(a.scheduleDate) - new Date(b.scheduleDate));
        let projectsWithUpcomingConference = []
        let projectsWithoutUpcomingConference = []
        for(let i = 0; i < list.length; i++){
            if(upcomingConferences.some(conference => conference.project === list[i]._id)){
                projectsWithUpcomingConference.push(list[i])
            } else {
                projectsWithoutUpcomingConference.push(list[i])
            }
        }
        const conferenceIndexMap = new Map();
        sortedUpcomingConf.forEach((conference, index) => {
            conferenceIndexMap.set(conference.project, index);
        });
        projectsWithUpcomingConference.sort((a, b) => {
            const indexA = conferenceIndexMap.get(a._id);
            const indexB = conferenceIndexMap.get(b._id);
            return indexB - indexA;
        })
        let upcomingProjects = [...projectsWithUpcomingConference, ...projectsWithoutUpcomingConference]
        return upcomingProjects
    } else if(value === "5"){
        let createdProjectList = list.sort((a, b) => new Date(a.createdDate) - new Date(b.createdDate))
        return createdProjectList
    } else if(value === "6"){
        let createdProjectList = list.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
        return createdProjectList
    }

}