import { useRef, useContext, useState, useEffect } from 'react';

import { GlobalContext } from "../../contexts/globalContext";
import { ProjectDetailsContext } from '../../contexts/projectDetailsContext';

import uuid from 'react-uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';
import styles from './AddConferenceComponent.module.css'
import InputTextComponent from '../keyboard/InputTextComponent';
import KeyboardComponent from '../keyboard/KeyboardComponent';
import PopupHeaderComponent from './common/PopupHeaderComponent';
import PopupBodyComponent from './common/PopupBodyComponent';
import PopupBaseComponent from './common/PopupBaseComponent';
import ButtonComponent from '../commons/ButtonComponent'
import ClosePageButtonComponent from '../commons/ClosePageButtonComponent'
import CheckboxComponent from './common/CheckboxComponent';
import CustomDropdownComponent from '../commons/CustomDropdownComponent'
import LeftBodyComponent from './common/LeftBodyComponent';
import RightBodyComponent from './common/RightBodyComponent';
import 'react-datepicker/dist/react-datepicker.css';

import filesAdd from '../../graphics/Popups/IconFileAdd.png'
import DarkButtonComponent from '../commons/DarkButtonComponent';
import LightButtonComponent from '../commons/LightButtonComponent';
import ElementTableComponent from './common/ElementTableComponent';
import OutsideClickHandler from 'react-outside-click-handler';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

const StyledDatePickerWrapper = styled.div`
  .react-datepicker {
    width: 800px !important;
    height: 750px !important;
    background-color: #261E41 !important;
    border-radius: 20px;
    border-color: transparent;
  }

  .react-datepicker__month-container {
    height: 100%;
    width: 100%;
    color: #eeeeee;
    font-size: 20px;
  }

  .react-datepicker__header {
    background-color: #261E41  !important;
    border-bottom: none;
  }

  .react-datepicker__week {
    display: flex;
    height: 90.5px;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .react-datepicker__current-month {
    font-size: 60px;
    height: 130px;
    color: white;
    align-items: center;
    display: flex;
    font-weight: normal;
    justify-content: space-around;
  }

  .react-datepicker__day-names {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    color: #eeeeee;
    font-size: 35px;
    margin-top: 20px;
  }

  .react-datepicker__day-name {
    color: #eeeeee;
  }

  .react-datepicker__day {
    font-size: 40px;
    width: 70px;
    color: #eeeeee;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .react-datepicker__day:hover {
    background-color: #adcced !important;
    color: black !important;
  }

  .react-datepicker__day--weekend {
    color: #eeeeee !important;
  }

  .react-datepicker__day--today {
    background-color: #555 !important;
    color: #fff !important;
  }

  .react-datepicker__day--selected {
    background-color: #adcced !important;
    color: black !important;
    border-radius: 10% !important;
    margin: 0px;
  }

  .react-datepicker__day--disabled {
    color: #ccc !important;
  }
    
.react-datepicker__day--outside-month {
        color: #e3ca93 !important;
}
  .react-datepicker__day--before-today {
    opacity: 0.5;
    color: #4e5181 !important; /* color for days outside current month and days before today */
  }

  .react-datepicker__navigation--next {
    right: 80px !important;
    border-left-width: 10px;
    border-top-width: 10px;
    border-bottom-width: 10px;
  }

  .react-datepicker__navigation--previous {
    left: 80px;
    border-right-width: 10px;
    border-top-width: 10px;
    border-bottom-width: 10px;
  }

  .react-datepicker__navigation-icon::before {
    width: 30px;
    height: 30px;
  }

  .react-datepicker__navigation {
    width: 80px;
    height: 80px;
    top: 25px;
  }

  .react-datepicker__day:hover,
  .react-datepicker__day:focus,
  .react-datepicker__day--keyboard-selected {
    background-color: initial;
    color: white;
    outline: none;
    box-shadow: none;
  }
`;

const AddConferenceComponent = () => {
    const inputRef = useRef();
    const keyboardRef = useRef();
    const [showTimePicker, setShowTimePicker] = useState(false);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [currentTime, setCurrentTime] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date());

    const { language, selectedProject, socketRef } = useContext(GlobalContext);
    const { setCreateConference, editConference, setEditConference } = useContext(ProjectDetailsContext);

    // Get the current hour in Bucharest timezone
    const getCurrentHourInBucharest = () => {
        const now = new Date();
        const bucharestTime = new Date(now.toLocaleString("en-US", { timeZone: "Europe/Bucharest" }));
        return bucharestTime.getHours().toString().padStart(2, '0');
    };

    const [hour, setHour] = useState(editConference ? new Date(editConference.scheduleDate).getHours().toString().padStart(2, '0') : getCurrentHourInBucharest());
    const [minute, setMinute] = useState(editConference ? new Date(editConference.scheduleDate).getMinutes().toString().padStart(2, '0') : '00');

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            const bucharestTime = new Date(now.toLocaleString("en-US", { timeZone: "Europe/Bucharest" }));
            const currentHour = bucharestTime.getHours();
            const currentMinute = bucharestTime.getMinutes();
            const formattedTime = `${currentHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`;
            setCurrentTime(formattedTime);
        };

        updateTime();
        const timeInterval = setInterval(updateTime, 1000);
        return () => clearInterval(timeInterval);
    }, []);

    const handleHourChange = (e) => setHour(e.target.value);
    const handleMinuteChange = (e) => setMinute(e.target.value);
    const toggleTimePicker = () => setShowTimePicker(!showTimePicker);
    const toggleDatePicker = () => setShowDatePicker(!showDatePicker);
    const handleDateChange = (date) => setSelectedDate(new Date(date));

    const formatSelectedDate = (date) => {
        const options = { weekday: 'long', day: 'numeric', month: 'long' };
        return new Date(date).toLocaleDateString('en-US', options);
    };

    const generateHourOptions = () => {
        const hours = [];
        for (let h = 0; h < 24; h++) {
            hours.push(h < 10 ? '0' + h : '' + h);
        }
        return hours.map((hour) => <option key={hour} value={hour}>{hour}</option>);
    };

    const generateMinuteOptions = () => {
        const minutes = [];
        for (let m = 0; m < 60; m++) {
            minutes.push(m < 10 ? '0' + m : '' + m);
        }
        return minutes.map((minute) => <option key={minute} value={minute}>{minute}</option>);
    };

    const addConference = () => {
        const formattedDate = `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}T${hour}:${minute}:00.000Z`;
        socketRef.current.emit("ADD_EDIT_CONFERENCE", {
            projectId: selectedProject._id,
            conferenceId: editConference ? editConference._id : null,
            newData: {
                name: inputRef.current?.value ? inputRef.current.value : "Default Title",
                scheduleDate: formattedDate
            }
        }, (conference) => {
            // handle the response if needed
        });

        setCreateConference();
        setEditConference();
    };

    const isBeforeToday = (date) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return date < today;
      };

    return (
        <PopupBaseComponent>
            <PopupBodyComponent style={{ width: '2924px', height: '1060px' }} type="large">
                <LeftBodyComponent style={{ width: '1162px' }} image={filesAdd} title="New conference" description="Enter details to schedule your event" />
                <ClosePageButtonComponent setClose={() => { editConference ? setEditConference(false) : setCreateConference(false) }} />
                <RightBodyComponent flex={true}>
                    <label className={styles.inputTitle}>Title</label>
                    <div className={styles.inputDiv}>
                        <InputTextComponent ref={inputRef} keyboardRef={keyboardRef} value={editConference ? editConference.name : ""} fontSize={40} styleType={'POPUP'} width='1600px' placeHolder='Enter conference title here' />
                    </div>
                    <div className={styles.dateTimeDiv}>
                        <label className={styles.dateTimeLabel}>Date and time</label>
                        <div className={styles.pickers}>
                                <StyledDatePickerWrapper>
                                    <DatePicker
                                        selected={selectedDate}
                                        onChange={handleDateChange}
                                        customInput={
                                            <div className={styles.inputPicker} style={{top: '-68px'}}>
                                                <input
                                                    type="text"
                                                    value={editConference ? formatSelectedDate(editConference.scheduleDate) : formatSelectedDate(selectedDate)}
                                                    readOnly
                                                    className={styles.clockText}
                                                    style={{ background: 'transparent', border: 'none', outline: 'none' }}
                                                />
                                                <FontAwesomeIcon icon={byPrefixAndName.fal['calendar']} className={styles.clockIcon} />
                                            </div>
                                        }
                                        dayClassName={date =>
                                            isBeforeToday(date) ? 'react-datepicker__day--before-today' : ''
                                          }
                                        calendarStartDay={1}
                                        popperPlacement="bottom-start"
                                        popperClassName={styles.customPopper}
                                        calendarClassName={styles.calendar}
                                        dateFormat="MM/dd/yyyy"
                                        showPopperArrow={false}
                                    />
                                </StyledDatePickerWrapper>
                        </div>
                        <div className={styles.pickers} style={{ left: '823.69px' }}>
                            <div className={styles.inputPicker} onClick={toggleTimePicker} >
                                <div className={styles.clockText}>{hour}:{minute}</div>
                                <FontAwesomeIcon icon={byPrefixAndName.fal['clock']} className={styles.clockIcon} />
                            </div>
                            {showTimePicker && (
                                <OutsideClickHandler onOutsideClick={() => setShowTimePicker(false)}>
                                    <div className={styles.popupTimePicker}>
                                        <label className={styles.nowLabel}>NOW</label>
                                        <label className={styles.nowTime}>{currentTime}</label>
                                        <div className={styles.hourDiv}>
                                            <label className={styles.hourLabel}>Hour</label>
                                            <select id="hour" value={hour} onChange={handleHourChange} size={10} className={styles.selectHour}>
                                                {generateHourOptions()}
                                            </select>
                                        </div>
                                        <div className={styles.hourDiv} style={{ left: '213.63px' }}>
                                            <label className={styles.hourLabel}>Minute</label>
                                            <div className={styles.hourDiv} style={{ top: '0px', left: '9px' }}>
                                                <select id="minute" value={minute} onChange={handleMinuteChange} size={10} className={styles.selectHour}>
                                                    {generateMinuteOptions()}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </OutsideClickHandler>
                            )}
                        </div>
                    </div>
                    <div className={styles.buttonsDiv}>
                        <LightButtonComponent style={{ width: '377px', height: '82px', top: '-10px' }} className={styles.createButton} onClick={addConference} >
                            <FontAwesomeIcon className={styles.iconPlane} icon={byPrefixAndName.fas['plus']} /> {!editConference ? "Create" : 'Save'}
                        </LightButtonComponent>
                        <LightButtonComponent style={{ width: '759px', height: '82px', left: '417px', position: 'absolute' }}>
                            <FontAwesomeIcon className={styles.iconPlane} icon={byPrefixAndName.fas['paper-plane']} /> {!editConference ? "Create and Send Invitations" : "Save and Send Invitations"}
                        </LightButtonComponent>
                        <DarkButtonComponent
                            style={{
                                width: '377px',
                                height: '82px',
                                left: '1236px',
                                position: 'absolute',
                            }}
                            onClick={editConference ? setEditConference : setCreateConference}
                        >
                            <FontAwesomeIcon className={styles.iconPlane} style={{ width: '33px', height: '40px' }} icon={byPrefixAndName.fas['xmark']} /> Cancel
                        </DarkButtonComponent>
                    </div>
                </RightBodyComponent>
                {/* <KeyboardComponent visible ref={keyboardRef} x="550" y="180" width="750" height="450" s="1.8" /> */}
            </PopupBodyComponent>

        </PopupBaseComponent>
    );

}

export default AddConferenceComponent;