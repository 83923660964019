import styles from './PopupBodyComponent.module.css'

const PopupBodyComponent = ({ children, style }) => {

    return (
        <div className={styles.body_large} style={style}>
            {children}
        </div>
    );

}

export default PopupBodyComponent;