import { useRef, useContext, useState } from 'react';
import styles from './AddMembersComponent.module.css'

import { GlobalContext } from "../../contexts/globalContext";
import { ProjectDetailsContext } from '../../contexts/projectDetailsContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import InputTextComponent from '../keyboard/InputTextComponent';
// import KeyboardComponent from '../keyboard/KeyboardComponent';
import PopupHeaderComponent from './common/PopupHeaderComponent';
import PopupBodyComponent from './common/PopupBodyComponent';
import PopupBaseComponent from './common/PopupBaseComponent';
import ClosePageButtonComponent from '../commons/ClosePageButtonComponent'
import RightBodyComponent from './common/RightBodyComponent';
import LeftBodyComponent from './common/LeftBodyComponent';

import membersAdd from '../../graphics/Popups/IconMembersAdd.png'
import FooterButtonsComponent from './common/FooterButtonsComponent';
import OutsideGlowComponent from './common/OutsideGlowComponent';
import CustomDropdownComponent from '../commons/CustomDropdownComponent';


const AddMembersComponent = () => {

    const inputRefName = useRef()
    const inputRefEmail = useRef()
    const inputRefPass = useRef()
    const inputRefPassConf = useRef()
    const keyboardRef = useRef()

    const [isOnFocusName, setIsOnFocusName] = useState(false)
    const [isOnFocusEmail, setIsOnFocusEmail] = useState(false)
    const [isOnFocusPass, setIsOnFocusPass] = useState(false)
    const [isOnFocusPassConf, setIsOnFocusPassConf] = useState(false)


    const { language, selectedProject, socketRef, departmentList, functionList } = useContext(GlobalContext);
    const { setAddMembers } = useContext(ProjectDetailsContext);

    const [currentChoiceDepartment, setCurrentChoiceDepartment] = useState({
        value: "AMN",
        text: "Admin"
    })

    const [currentChoiceFunction, setCurrentChoiceFunction] = useState({
        value: "PROG",
        text: "Programmer"
    })


    const optionsDepartament = [
        {
            value: "AMN",
            text: "Admin"
        },
        {
            value: "DEVL",
            text: "Development"
        },
        {
            value: "GST",
            text: "GUEST"
        }, {
            value: "NTF",
            text: "NOTIF"
        }, 
    ]

    const optionsFunction = [
        {
            value: "PROG",
            text: "Programmer"
        },
        {
            value: "ADMN",
            text: "Admin"
        },
        {
            value: "TST",
            text: "TEST"
        },
        {
            value: "GST",
            text: "GUEST"
        },
    ]


    function handleEmailAdd(){
        let memberFunction = functionList.find(func => func.code === currentChoiceFunction.value)
        let memberDepartment = departmentList.find(dep => dep.code === currentChoiceDepartment.value)
        socketRef.current.emit("CREATE_USER_AND_ADD_TO_PROJECT", { 
            projectId: selectedProject._id, 
            userData: {
                "name": inputRefName.current.value, 
                "email": inputRefEmail.current.value,
                "password": inputRefPass.current.value,
                "function_id": memberFunction._id,
                "department_id": memberDepartment._id,
                "role": "MEMBER",
            }
        }, (callback) => {
            if(callback) console.log(callback)
        })
        setAddMembers(false)
    }

    
    return (
        <PopupBaseComponent>
            <PopupHeaderComponent text={language.add_guests['Add Guest']}/>
            <PopupBodyComponent style={{width: 4251, height: 1633}}>
                <ClosePageButtonComponent setClose={() => setAddMembers(false)} />
                <LeftBodyComponent image={membersAdd} title="Add member to project" description="Create and add a new member. You will automatically add this as a member in the organization, as well as a member to the project."/>
                <RightBodyComponent>
                    <div className={styles.title}>Name</div>
                    {isOnFocusName && <OutsideGlowComponent style={{top: 232}}/>}
                    <InputTextComponent ref={inputRefName} keyboardRef={keyboardRef} value="" placeHolder="Enter name here" styleType='POPUP' width= "1560px" fontSize="40px" setIsOnFocus={setIsOnFocusName}/>
                    {!isOnFocusName && <FontAwesomeIcon className={styles.iconEdit} icon={byPrefixAndName.fal['pen']} />}
                    <div className={styles.title2}>Email</div>
                    {isOnFocusEmail && <OutsideGlowComponent style={{top: 456}}/>}
                    <InputTextComponent ref={inputRefEmail} keyboardRef={keyboardRef} value="" placeHolder="Enter email here" styleType='POPUP' width= "1560px" fontSize="40px" setIsOnFocus={setIsOnFocusEmail}/>
                    {!isOnFocusEmail && <FontAwesomeIcon className={styles.iconEdit} style={{top: 310}} icon={byPrefixAndName.fal['pen']} />}
                    <div className={styles.title2}>Password</div>
                    {isOnFocusPass && <OutsideGlowComponent style={{top: 682}}/>}
                    <InputTextComponent type="password" ref={inputRefPass} keyboardRef={keyboardRef} value="" placeHolder="Enter password here" styleType='POPUP' width= "1560px" fontSize="40px" setIsOnFocus={setIsOnFocusPass}/>
                    {!isOnFocusPass && <FontAwesomeIcon className={styles.iconEdit} style={{top: 535}} icon={byPrefixAndName.fal['pen']} />}
                    <div className={styles.title2}>Confirm Password</div>
                    {isOnFocusPassConf && <OutsideGlowComponent style={{top: 907}}/>}
                    <InputTextComponent type="password" ref={inputRefPassConf} keyboardRef={keyboardRef} value="" placeHolder="Renter password here" styleType='POPUP' width= "1560px" fontSize="40px" setIsOnFocus={setIsOnFocusPassConf}/>
                    {!isOnFocusPassConf && <FontAwesomeIcon className={styles.iconEdit} style={{top: 760}} icon={byPrefixAndName.fal['pen']} />}
                    <div className={styles.title3}>Department</div>
                    <CustomDropdownComponent options={optionsDepartament} currentChoice={currentChoiceDepartment} setCurrentChoice={setCurrentChoiceDepartment} width={1560}/>
                    <div className={styles.title3}>Function</div>
                    <CustomDropdownComponent options={optionsFunction} currentChoice={currentChoiceFunction} setCurrentChoice={setCurrentChoiceFunction} width={1560}/>
                    <FooterButtonsComponent lightText={"Add"} lightClick={handleEmailAdd} darkClick={setAddMembers}/>
                {/* <KeyboardComponent visible ref={keyboardRef} x="330" y="250" width="750" height="450" s="1.8"/> */}
                </RightBodyComponent>
            </PopupBodyComponent>
            
        </PopupBaseComponent>
    );

}

export default AddMembersComponent;