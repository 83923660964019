import { useRef, useContext, useState, useEffect } from 'react';

import { GlobalContext } from "../../contexts/globalContext";
import { ProjectDetailsContext } from '../../contexts/projectDetailsContext';

import uuid from 'react-uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';
import styles from './AddFilesComponent.module.css'
import InputTextComponent from '../keyboard/InputTextComponent';
import KeyboardComponent from '../keyboard/KeyboardComponent';
import PopupHeaderComponent from './common/PopupHeaderComponent';
import PopupBodyComponent from './common/PopupBodyComponent';
import PopupBaseComponent from './common/PopupBaseComponent';
import ButtonComponent from '../commons/ButtonComponent'
import ClosePageButtonComponent from '../commons/ClosePageButtonComponent'
import CheckboxComponent from './common/CheckboxComponent';
import CustomDropdownComponent from '../commons/CustomDropdownComponent'
import LeftBodyComponent from './common/LeftBodyComponent';
import RightBodyComponent from './common/RightBodyComponent';

import filesAdd from '../../graphics/Popups/IconFileAdd.png'
import DarkButtonComponent from '../commons/DarkButtonComponent';
import LightButtonComponent from '../commons/LightButtonComponent';
import ElementTableComponent from './common/ElementTableComponent';

const AddFilesComponent = () => {


    const inputRef = useRef()
    const keyboardRef = useRef()
    const inputFileRef = useRef()
    const selectRef = useRef()
    const docsRef = useRef()
    const inputLocalFileNameRef = useRef()

    const [docs, setDocs] = useState()
    const [allDocs, setAllDocs] = useState()

    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);

    const [fileData, setFileData] = useState(null);

    const [currentChoice, setCurrentChoice] = useState({
        value: "all",
        text: "All"
    })

    const handleBrosweButtonClick = () => {
        inputFileRef.current.click();
    };

    const { language, currentUser, selectedProject, socketRef, selectedParentForFiles, selectedRefTypeForFiles } = useContext(GlobalContext);
    const { setAddFiles } = useContext(ProjectDetailsContext);


    const optionsTypes = [
        {
            value: "all",
            text: "All"
        },
        {
            value: "image",
            text: "Image"
        },
        {
            value: "pdf",
            text: "PDF"
        },
        {
            value: "word",
            text: "Word"
        },
        {
            value: "ms-excel",
            text: "Excel"
        },
        {
            value: "video",
            text: "Video"
        }
    ]


    useEffect(() => {
        socketRef.current.emit("GET_PROJECT_USER_DOCUMENTS_TO_ADD", { projectId: selectedProject._id, userId: currentUser._id }, (DOCS) => {
            setAllDocs(DOCS)
            setDocs(DOCS)
            docsRef.current = DOCS
        })

    }, [])


    const handleClickSelect = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
    };

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(docs.map(doc => doc._id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    function handleFilesAdd() {
        let fileList = []
        for (let i = 0; i < docs.length; i++) {
            if (isCheck.includes(docs[i]._id)) {
                fileList.push(docs[i]._id)
            }
        }
        socketRef.current.emit("ADD_EXISTING_TOPIC_DOCUMENTS", { docIds: fileList, userId: currentUser._id, refType: selectedRefTypeForFiles, parentId: selectedParentForFiles, projectId: selectedProject._id },
            (callback) => {  })
        setAddFiles(false)
    }

    const handleSelectType = e => {
        const value = e.target.value;
        if (value !== "all") {
            const filteredDocs = allDocs.filter(doc => doc.type.includes(value));
            setDocs(filteredDocs);
        } else {
            setDocs(allDocs)
        }

    };

    const onFileChange = event => {

        if (event.target.files.length > 0) {

            let file = event.target.files[0]
            setFileData(file)
        }

    };

    function addDocument() {
        let type = fileData ? fileData.type : null;

        var data = { ownerId: currentUser._id, uploadFile: fileData, type, fileName: inputLocalFileNameRef?.current.value ? inputLocalFileNameRef?.current.value : `${type} by ${currentUser.name}` };

        socketRef.current.emit("ADD_NEW_TOPIC_DOCUMENT", { data, parentId: selectedParentForFiles, refType: selectedRefTypeForFiles, projectId: selectedProject._id }, 
            (callback) => { })
        setAddFiles(false);
    }

    function onTextChanged() {
        let result = []
        if (inputRef.current.value) {
            result = docsRef.current.filter(doc => doc.name.toLowerCase().includes(inputRef.current.value.toLowerCase()));
        } else {
            result = docsRef.current
        }
        if (selectRef.current.value !== "all")
            result = result.filter(doc => doc.type.includes(selectRef.current.value));
        setDocs(result)
    }

    function handleClickAdd() {
        if (isCheck.length > 0) {
            handleFilesAdd();
        }
        if (fileData) {
            addDocument();
        }

    }

    function handleClose() {
        setAddFiles(false)
    }

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.files.length > 0) {
            let file = e.dataTransfer.files[0];
            setFileData(file);
        }
    };

    return (
        <PopupBaseComponent>
            <PopupBodyComponent style={{ width: '4579px', height: '1460px' }} type="large">
                <LeftBodyComponent style={{ width: '1167px' }} image={filesAdd} title="Add files" description="Upload local files or search and select from cloud DOCS to add to the project. You can include various file types to enhance project resources and collaboration." />
                <ClosePageButtonComponent setClose={() => setAddFiles(false)} />
                <RightBodyComponent flex={true}>
                    <div className={styles.localFiles} >
                        <label className={styles.localFilesLabel}> Add local Files </label>
                        <input
                            ref={inputFileRef}
                            type="file"
                            multiple
                            onChange={onFileChange}
                            className={styles.input}
                            style={{ display: 'none' }}
                        />
                        <DarkButtonComponent style={{position: 'absolute', top: '219.5px', left: '1304.5px', width: '502px',height: '82px'}} onClick={handleBrosweButtonClick}>
                            Browse File
                        </DarkButtonComponent>
                        <div className={styles.inputName} >
                            <InputTextComponent ref={inputLocalFileNameRef} styleType='POPUP' fontSize="30px" keyboardRef={keyboardRef} value="" placeHolder={language.add_files["File name"]}
                                onTextChanged={() => { }}
                            />
                            <div className={styles.localFileName} >{fileData?.name}</div>
                        </div>
                        <div className={styles.orLine}></div>
                        <LightButtonComponent style={{
                            position: 'absolute',
                            bottom: '79.5px',
                            left: '1283.5px',
                            width: '329px',
                            height: '82px',
                        }} className={styles.addButton}
                            onClick={handleClickAdd}>
                            <FontAwesomeIcon className={styles.iconPlane} icon={byPrefixAndName.fas['plus']} /> Add
                        </LightButtonComponent>
                        <DarkButtonComponent
                            style={{
                                width: '377px',
                                height: '82px',
                                left: '1692.5px',
                                bottom: '79.5px',
                                position: 'absolute',
                            }}
                            onClick={handleClose}
                        >
                            <FontAwesomeIcon className={styles.iconPlane} style={{ width: '33px', height: '40px' }} icon={byPrefixAndName.fas['xmark']} /> Cancel
                        </DarkButtonComponent>
                        <div className={styles.dragDropZone} onDragOver={handleDragOver} onDrop={handleDrop}>
                            <label className={styles.dragDropLabel}>Drag and drop local files here</label>
                        </div>
                        <div className={styles.orLine} style={{ top: '1249.5px' }}></div>
                        <div className={styles.verLine}></div>

                        <label className={styles.docsFilesLabel}>Add DOCS files</label>
                        <div className={styles.buttonsDiv}>
                            <InputTextComponent position='absolute' ref={inputRef} width='801' fontSize="30" keyboardRef={keyboardRef} value="" styleType='SEARCHPOPUP' placeHolder={language.add_participants.Search} onTextChanged={onTextChanged} />
                        </div>
                        <div className={styles.selectListDiv}>
                            <CustomDropdownComponent options={optionsTypes} currentChoice={currentChoice} setCurrentChoice={setCurrentChoice} width={573} />
                        </div>
                        <div className={styles.divCheckBox}>
                            <div className={styles.tableHeader}>
                                <CheckboxComponent text={language.add_participants['Select All']} checked={isCheckAll} onChange={handleSelectAll} />
                                <div className={styles.centerElement}>Type</div>
                            </div>

                            <ElementTableComponent isFiles='true' docs={docs} isCheck={isCheck} onChange={handleClickSelect} style={{ paddingLeft: 0, marginTop: 5 }} />
                        </div>
                    </div>
                </RightBodyComponent>
                {/* <KeyboardComponent visible ref={keyboardRef} x="550" y="180" width="750" height="450" s="1.8" /> */}
            </PopupBodyComponent>

        </PopupBaseComponent>
    );

}

export default AddFilesComponent;