import { useRef, useEffect, useContext } from 'react';

import { GlobalContext } from "./contexts/globalContext";

import styles from './app.module.css';
import LoginComponent from './components/LoginComponent';
import ProjectsListComponent from './components/ProjectsListComponent';
import { jwtDecode } from 'jwt-decode';

function App() {

    const windowRef = useRef();   
    const { usingInvitation, setCurrentProject, currentUser, setProjectsList, setGuestLogin, setUserLogin } = useContext(GlobalContext);

    useEffect(() => {

        windowRef.current.style.transformOrigin = 'top left';
        windowRef.current.style.transform = `scale(${window.innerWidth / process.env.REACT_APP_WIDTH}, ${window.innerHeight / process.env.REACT_APP_HEIGHT})`;

        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token')

        if (token) {
            let tokenData = getUserDataFromToken(token)
            if(tokenData.role === "GUEST"){
                setGuestLogin(tokenData)
            } else {
                setUserLogin(tokenData)
            }
                
        }
    }, [])

    const getUserDataFromToken = (token) => {
        try {
            const decodedToken = jwtDecode(token);
            const { id, name, email, role } = decodedToken;
            return { id, name, email, role } 
        } catch (error) {
            console.error('Invalid token', error);
            return null;
        }
    };

    return (
        <div ref={windowRef} className={styles.app_body} style={{ width: parseInt(process.env.REACT_APP_WIDTH), height: parseInt(process.env.REACT_APP_HEIGHT) }}>
            {!currentUser && <LoginComponent />}
            {currentUser && <ProjectsListComponent />}
        </div>
    );
}

export default App;
