import { useRef, useContext} from 'react';

import { GlobalContext } from "../../contexts/globalContext";
import { ProjectDetailsContext } from '../../contexts/projectDetailsContext';

import InputTextComponent from '../keyboard/InputTextComponent';
import KeyboardComponent from '../keyboard/KeyboardComponent';
import PopupHeaderComponent from './common/PopupHeaderComponent';
import PopupBodyComponent from './common/PopupBodyComponent';
import PopupBaseComponent from './common/PopupBaseComponent';
import ButtonComponent from '../commons/ButtonComponent'
import ClosePageButtonComponent from '../commons/ClosePageButtonComponent'

const EditNameComponent = () => {

    const inputRef = useRef()
    const keyboardRef = useRef()

    const { language, selectedProject, setSelectedProject, socketRef } = useContext(GlobalContext);
    const { setEditName } = useContext(ProjectDetailsContext);


    function handleEditName(){
        socketRef.current.emit("ADD_EDIT_PROJECT", {projectId: selectedProject._id, newData: {name: inputRef.current?.value ? inputRef.current?.value : selectedProject.name}}, 
            (project) => {
                let updatedName = {name: project.name ? project.name : selectedProject.name}
                setSelectedProject(selectedProject => ({
                    ...selectedProject,
                    ...updatedName
                }))
                setEditName()
            }
        );
    }

    
    return (
        <PopupBaseComponent>
            <ClosePageButtonComponent setClose={() => setEditName(false)} />
            <PopupHeaderComponent text={language.edit_name['Edit Name']}/>
            <PopupBodyComponent>
                {language.edit_name.Name}
                <InputTextComponent ref={inputRef} keyboardRef={keyboardRef} value={selectedProject.name} placeHolder="" />
                <ButtonComponent text={language.edit_name.Edit} style={{marginTop:30}} onClick={() => handleEditName()}/>
                <KeyboardComponent visible ref={keyboardRef} x="330" y="250" width="750" height="450" s="1.8"/>
            </PopupBodyComponent>
        </PopupBaseComponent>
    );

}

export default EditNameComponent;