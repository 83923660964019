import { useRef, useContext, useState } from 'react';
import styles from './AddGuestsComponent.module.css'

import { GlobalContext } from "../../contexts/globalContext";
import { ProjectDetailsContext } from '../../contexts/projectDetailsContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';

import InputTextComponent from '../keyboard/InputTextComponent';
// import KeyboardComponent from '../keyboard/KeyboardComponent';
import PopupHeaderComponent from './common/PopupHeaderComponent';
import PopupBodyComponent from './common/PopupBodyComponent';
import PopupBaseComponent from './common/PopupBaseComponent';
import ButtonComponent from '../commons/ButtonComponent'
import ClosePageButtonComponent from '../commons/ClosePageButtonComponent'
import RightBodyComponent from './common/RightBodyComponent';
import LeftBodyComponent from './common/LeftBodyComponent';

import membersAdd from '../../graphics/Popups/IconMembersAdd.png'
import FooterButtonsComponent from './common/FooterButtonsComponent';
import OutsideGlowComponent from './common/OutsideGlowComponent';


const AddGuestComponent = () => {

    const inputRef = useRef()
    const keyboardRef = useRef()

    const [isOnFocus, setIsOnFocus] = useState(false)

    const { language, selectedProject, socketRef } = useContext(GlobalContext);
    const { setAddGuest } = useContext(ProjectDetailsContext);


    function handleEmailAdd(){
        socketRef.current.emit("CREATE_USER_AND_ADD_TO_PROJECT", { 
            projectId: selectedProject._id, 
            userData: {
                "emailString": inputRef.current.value,
                "role": 'GUEST'
            } 
        }, (callback) => {
            console.log("GUEST", callback)
        })
        setAddGuest(false)
    }

    
    return (
        <PopupBaseComponent>
            <PopupHeaderComponent text={language.add_guests['Add Guest']}/>
            <PopupBodyComponent style={{width: 4251, height: 1633}}>
                <ClosePageButtonComponent setClose={() => setAddGuest(false)} />
                <LeftBodyComponent image={membersAdd} title="Add guest to project" description="Enter the email addresses of the guest users you wish to add to the project. These guests will receive access to project resources and notifications."/>
                <RightBodyComponent>
                    <div className={styles.title}>Email</div>
                    {isOnFocus && <OutsideGlowComponent />}
                    <InputTextComponent ref={inputRef} keyboardRef={keyboardRef} value="" placeHolder="Enter email(s) here" styleType='POPUP' width= "1560px" fontSize="40px" setIsOnFocus={setIsOnFocus}/>
                    {!isOnFocus && <FontAwesomeIcon className={styles.iconEdit} icon={byPrefixAndName.fal['pen']} />}
                    <div className={styles.explanation}>You can add multiple email addresses separated by a colon (:)</div>
                    <FooterButtonsComponent lightText={"Add"} lightClick={handleEmailAdd} darkClick={setAddGuest}/>
                {/* <KeyboardComponent visible ref={keyboardRef} x="330" y="250" width="750" height="450" s="1.8"/> */}
                </RightBodyComponent>
            </PopupBodyComponent>
            
        </PopupBaseComponent>
    );

}

export default AddGuestComponent;