
import styles from './AddButtonComponent.module.css'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';


const AddButtonComponent = ({onClick, icon, text}) => {


    return (
        <div className={styles.component} >
            <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fal[icon]} onClick={onClick}/>
            <div>{text}</div>
        </div>
    );
}

export default AddButtonComponent;