
import { useContext, useRef, useState } from 'react';
import styles from './PD_ParticipantComponent.module.css'
import { ProjectDetailsContext } from "../../../contexts/projectDetailsContext";
import { GlobalContext } from "../../../contexts/globalContext";

import InitialsCircleComponent from '../../commons/InitialsCircleComponent'
import MoreButtonComponent from '../../commons/MoreButtonComponent';



const PD_ParticipantComponent = ({ participant }) => {
    // const [moreClicked, setMoreClicked] = useState(false)
    const moreButtonRef = useRef()

    const { language, currentUser, socketRef, selectedProject } = useContext(GlobalContext);

    const {  selectedParticipant, setSelectedParticipant, moreClicked, setMoreClicked } = useContext(ProjectDetailsContext);

    function deleteParticipant(){
        socketRef.current.emit("DELETE_PARTICIPANT_FROM_PROJECT",
            { projectId: selectedProject._id, participantId: participant._id }, () => {
                setMoreClicked({})
            }
        );
    }

    function handleClick(){
        if(moreClicked.shown && participant._id === moreClicked.id){
            setMoreClicked({})
        } else {
            setMoreClicked({...moreClicked, 
                shown: true, 
                id: participant._id,
                clickRemove: () => deleteParticipant(),
                x: moreButtonRef.current.getBoundingClientRect().x,
                y: moreButtonRef.current.getBoundingClientRect().y
            })
        }
        
        
    }

    return (
    <>
        <div key={participant._id} className={styles.element}>
            <div className={styles.info}>
                <InitialsCircleComponent name = {participant.name} />
                <div className={styles.participantDetails}>
                    {participant.name}
                    <div className={styles.participantDescription}>
                        Role: 
                        <div className={styles.participantRole}>{participant.role}</div>
                    </div>
                    {participant.department && <div className={styles.participantDescription}>
                        Department: 
                        <div className={styles.participantRole}>{participant.department.name}</div>
                    </div>}
                </div>
            </div>
            <MoreButtonComponent ref={moreButtonRef} clicked={moreClicked} setClicked={handleClick} id={participant._id}/>
            
            {/* {selectedParticipant?._id === participant._id && 
                <ButtonComponent text={language.project_details.Delete} style={{position: "absolute", top:5, right:5, width:100, height:50, fontSize: 30}} onClick={() => deleteParticipant(selectedParticipant._id)}/>
            } */}
        </div>
    </>
        


    );

}

export default PD_ParticipantComponent;