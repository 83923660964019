import styles from './OutsideGlowComponent.module.css'



const OutsideGlowComponent = ({style}) => {
    
    return (
        <div className={styles.component} style={style}/>
    );

}

export default OutsideGlowComponent;