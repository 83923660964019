import styles from './InitialsCircleComponent.module.css'


const InitialsCircleComponent = ({name, onClick}) => {


    function capitalizeFirstAndLast() {
        if(name){
            name = name.trim();
            const words = name.split(" ");
            if(words.length > 1)
                return words[0].charAt(0).toUpperCase() + words[words.length - 1].charAt(0).toUpperCase();
            return words[0].charAt(0).toUpperCase()
        }
        return ""
    }



    return (
        <div className={styles.current_user} onClick={onClick}>
            {capitalizeFirstAndLast(name)}
        </div>
          

    );

}

export default InitialsCircleComponent;