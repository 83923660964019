import { useContext, useEffect } from 'react';
import styles from './PD_FileListComponent.module.css';

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons'

import PD_BaseListComponent from '../PD_BaseListComponent';
import PD_FileComponent from './PD_FileComponent';
// import PD_ListFooterButtonsComponent from '../PD_ListFooterButtonsComponent';
// import ButtonComponent from '../../commons/ButtonComponent';
import DarkButtonComponent from '../../commons/DarkButtonComponent';


const FileListComponent = () => {
    const { language, selectedProject, topicFiles } = useContext(GlobalContext);
    const { setAddFiles } = useContext(ProjectDetailsContext);


    return (
        <PD_BaseListComponent
            title = {language.project_details.Files}
            list = {topicFiles.length > 0 && topicFiles.map((file) => (
                <PD_FileComponent key={file._id} file={file}/>
            ))}
            button = {selectedProject.topics.length > 0 &&
                <DarkButtonComponent onClick={() => setAddFiles(true)} style={{width: 1056}}>
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['files']} />
                    {language.project_details['Add files']}
                </DarkButtonComponent>
            }
        />

    );

}

export default FileListComponent;