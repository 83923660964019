export function ApiLoginUser(body, callback) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: body
    };

    fetch(process.env.REACT_APP_DOCS_URL + '/api/login', requestOptions)
        .then(response => response.json())
        .then(data => {
            callback?.(data);
        });
}

export function ApiLogoutUser(token, callback) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': token },
        // body: body
    };


    fetch(process.env.REACT_APP_DOCS_URL + '/api/logout', requestOptions)
        .then(response => response.json())
        .then(data => {
            callback?.(data);
        });
}


