
import { useContext, useRef } from 'react';
import styles from './PD_ConferenceComponent.module.css'
import { ProjectDetailsContext } from "../../../contexts/projectDetailsContext";
import { GlobalContext } from "../../../contexts/globalContext";

import MoreButtonComponent from '../../commons/MoreButtonComponent';

import TagComponent from '../../projectList/TagComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';
import DarkButtonComponent from '../../commons/DarkButtonComponent';
import LightButtonComponent from '../../commons/LightButtonComponent';



const PD_ConferenceComponent = ({ conference }) => {
    const moreButtonRef = useRef()

    const { socketRef, currentUser, selectedProject, language, detailsUpcomingConference, setConferenceScreen, setCurrentConference } = useContext(GlobalContext);

    const {  setEditConference, moreClicked, setMoreClicked } = useContext(ProjectDetailsContext);

    const date = new Date(conference.scheduleDate)

    function deleteConference(){
        socketRef.current.emit("DELETE_CONFERENCE", conference._id, selectedProject._id, () => setMoreClicked({}))
    }

    function handleClick(){
        if(moreClicked.shown && conference._id === moreClicked.id){
            setMoreClicked({})
        } else {
            setMoreClicked({...moreClicked, 
                shown: true, 
                id: conference._id,
                clickRemove: () => deleteConference(),
                clickEdit: () => setEditConference(conference),
                x: moreButtonRef.current.getBoundingClientRect().x,
                y: moreButtonRef.current.getBoundingClientRect().y
            })
        }
    }

    return (
        <div className={detailsUpcomingConference?._id === conference._id ? styles.upcomingConference : styles.element}>
            <div className={styles.info}>
                <div>
                    <div className={styles.title_tags}>
                        {new Date(conference.scheduleDate) > new Date() && detailsUpcomingConference?._id !== conference._id &&
                        <TagComponent status="active" text="Future"/>}
                        {new Date(conference.scheduleDate) < new Date() && 
                        <TagComponent status="inactive" text="Past"/>}
                        {detailsUpcomingConference?._id === conference._id && 
                        <TagComponent status="live" text="Upcoming"/>}
                    </div>
                    {conference.name}
                </div>
                <MoreButtonComponent ref={moreButtonRef} clicked={moreClicked} setClicked={handleClick} id={conference._id}/>
            </div>
            <div className={styles.details}>
                <div className={styles.half_detail} style={{ borderRight: "4px solid #69627D"}}>
                    <div>
                        <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fal['calendar-days']} />
                        Date
                    </div>
                    <div className={styles.detail_accent}>
                        {date.toLocaleDateString('en-RO', { weekday: 'short'})}. {date.toLocaleDateString('en-RO', { day: '2-digit'})} {date.toLocaleDateString('en-RO', {month: 'short'})}. {date.toLocaleDateString('en-RO', { year: 'numeric' })}
                    </div>
                </div>
                <div className={styles.half_detail} >
                    <div>
                        <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fal['clock']} />
                        Starting at
                    </div>
                    <div className={styles.detail_accent}>
                        {date.toLocaleTimeString('en-RO', { hour12: false, hour: '2-digit', minute: '2-digit' })}
                    </div>
                </div>
            </div>
            {new Date(conference.scheduleDate) > new Date() && <div className={styles.buttons}>
                <DarkButtonComponent>
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['paper-plane']} />
                    Send Invitations
                </DarkButtonComponent>
                {detailsUpcomingConference?._id === conference._id && <LightButtonComponent onClick={() => {setConferenceScreen(true); setCurrentConference(conference._id) }}>
                    <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fas['arrow-right']} />
                    {currentUser?.function.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator" ? "START" : "JOIN"}
                </LightButtonComponent>}
            </div>}
        </div>


    );

}

export default PD_ConferenceComponent;