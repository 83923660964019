import { useContext, useEffect } from 'react'
import styles from './PD_BaseListComponent.module.css'
import { GlobalContext } from '../../contexts/globalContext'
import { ProjectDetailsContext } from '../../contexts/projectDetailsContext'



import titleSvg from '../../graphics/linie titlu.svg'

import wave1 from '../../graphics/waves-01.svg'
import wave2 from '../../graphics/waves-02.svg'
import wave3 from '../../graphics/waves-03.svg'
import wave4 from '../../graphics/waves-04.svg'

import conf from '../../graphics/Popups/IconConf.png'
import confAdd from '../../graphics/Popups/IconConfAdd.png'
import topics from '../../graphics/Popups/IconTopics.png'
import topicsAdd from '../../graphics/Popups/IconTopicsAdd.png'
import filesAdd from '../../graphics/Popups/IconFileAdd.png'



const PD_BaseListComponent = ({ title, list, button, toggleButton }) => {

    const { currentUser } = useContext(GlobalContext);
    const { setAddFiles, setAddParticipants, setAddGuest, setAddTopic, setCreateConference } = useContext(ProjectDetailsContext);

    function openPopup() {
        switch (title) {
            case 'Conferences':
                setCreateConference(true);
                break;
            case 'Topics':
                setAddTopic(true);
                break;
            case 'Directories':
                setAddTopic(true)
                break;
            default:
                setAddFiles(true)
                break;
        }
    };

    const image = (() => {
        switch (title) {
            case 'Conferences':
                return wave1;
            case 'Topics':
                return wave2;
            case 'Directories':
                return wave2;
            case 'Participants':
                return wave3;
            default:
                return wave4;
        }
    })();



    const noListImage = (() => {
        switch (title) {
            case 'Conferences':
                if (currentUser?.function.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    return confAdd;
                return conf;
            case 'Topics':
                if (currentUser?.function.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    return topicsAdd;
                return topics;
            case 'Directories':
                if (currentUser?.function.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    return topicsAdd;
                return topics;
            default:
                return filesAdd;
        }
    })();

    const imgTitle = (() => {
        switch (title) {
            case 'Conferences':
                if (currentUser?.function.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    return "Create New Conference";
                return "No Conference Created";
            case 'Topics':
                if (currentUser?.function.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    return "Add Project Topics & Subtopics";
                return "No Topics & Subtopics";
            case 'Directories':
                if (currentUser?.function.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    return "Add Project Directories & Subdirectories";
                return "No Directories & Subdirectories";
            default:
                return "Add Files to Topic";
        }
    })();

    const imgDescription = (() => {
        switch (title) {
            case 'Conferences':
                if (currentUser?.function.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    return "It looks like you don’t have any conferences. Open the main menu below to create your first conference and start organising your events.";
                return "It looks like you don’t have any conferences. Please check back later";
            case 'Topics':
                if (currentUser?.function.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    return "Tap “New Topic” button from the menu below to create topics and structure your discussions.";
                return "Looks like the discussion hasn't started yet!";
            case 'Directories':
                if (currentUser?.function.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")
                    return "Tap “New Directory” button from the menu below to create directories and structure your discussions.";
                return "Looks like the discussion hasn't started yet!";
            default:
                return "Select a topic/directory or subtopic/subdirectory to add files for sharing with other project members. You can upload files from your local device or search and select files from Cloud DOCS. Ensure the files are organized under the correct topic for easy access by the team.";
        }
    })();

    useEffect(() => {
        // console.log(list)
    }, [])


    return (
        <div className={styles.component}>
            <img src={image} className={styles.backgroundImage} alt={"fundal"} />
            <div className={styles.title}>
                <img className={styles.titleGraphic} alt="icon" src={titleSvg} />
                {title.toUpperCase()} ({list.length > 0 ? list.length : 0})
            </div>
            {toggleButton && <div className={styles.toggleButton}>{toggleButton}</div>}
            {button && <div className={styles.button}>{button}</div>}
            {list.length > 0 ?
                <div className={styles.list} style={{ marginTop: toggleButton ? -10 : "" }}>{list} </div>
                :
                <div className={styles.borderImg} style={{ marginTop: toggleButton ? 440 : (button ? 395 : "") }}>
                    <div className={styles.noListImageBackrgound} style={{ paddingBottom: title === "Files" ? 60 : "" }} onClick={openPopup}>
                        <img src={noListImage} className={styles.noListImage} alt={"noListImage"} />
                        <div className={styles.imgTitle}>{imgTitle}</div>
                        <div className={styles.imgDescription}>{imgDescription}</div>
                    </div>
                </div>
            }
        </div>


    );

}

export default PD_BaseListComponent;