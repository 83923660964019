import { useContext } from 'react';

import Form from 'react-bootstrap/Form';
import './custom.css'
// import 'bootstrap/dist/css/bootstrap.min.css';

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';

import PD_BaseListComponent from '../PD_BaseListComponent';
import PD_TopicComponent from './PD_TopicComponent';
// import PD_ListFooterButtonsComponent from '../PD_ListFooterButtonsComponent';
// import ButtonComponent from '../../commons/ButtonComponent';


const TopicListComponent = () => {
    const { language, currentUser, selectedProject } = useContext(GlobalContext);
    const { setAddTopic } = useContext(ProjectDetailsContext);


    return (
        <PD_BaseListComponent
            title = {language.project_details.Topics}
            toggleButton = {
                <Form>
                    <Form.Check 
                        type="switch"
                        id="custom-switch"
                        label="DIRECTORY"
                        className='custom-switch'
                        style={{font: "normal normal bold 32px/40px bio-sans"}}
                        // checked={isChecked} 
                        // onChange={handleSwitchChange} 
                    />
                </Form>
            }
            list = {selectedProject.topics?.map((topic) => (
                <PD_TopicComponent key={topic._id} topic={topic}/>
            ))}
            // buttons = {(currentUser?.function.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator")&& <PD_ListFooterButtonsComponent>
            //     <ButtonComponent text={language.project_details['Add topics']} style={{ marginTop:50}} onClick={() => setAddTopic(true)}/>
            // </PD_ListFooterButtonsComponent>}
        />
       

    );

}

export default TopicListComponent;