
import styles from './PopupHeaderComponent.module.css'

const PopupHeaderComponent = ({ text, type }) => {

    return (
        <div className={type==="large" ? styles.header_large : styles.header}>
            {text}
        </div>
    );

}

export default PopupHeaderComponent;