import styles from './ClosePageButtonComponent.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons';


const ClosePageButtonComponent = ({setClose}) => {


    return (
        <div className={styles.closeButton} onClick={setClose}>
            <FontAwesomeIcon icon={byPrefixAndName.fas['xmark']}  />
        </div>
    );
}

export default ClosePageButtonComponent;