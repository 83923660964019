import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GlobalProvider } from "./contexts/globalContext"
import { BrowserRouter, Route, Routes } from "react-router-dom"


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <BrowserRouter>
        <Routes>
            <Route path="/" element={<GlobalProvider>
                <App />
            </GlobalProvider>} />
            {/* <Route path="/:token" render={(props) => <GlobalProvider>
                <App {...props}/>
            </GlobalProvider>} /> */}
        </Routes>
    </BrowserRouter>
);


reportWebVitals();
