

import styles from './RightBodyComponent.module.css'



const RightBodyComponent = ({children, flex, width = 1600}) => {
    
    return (
        <div className={styles.component} style={{flexDirection: flex ? "row" : "", width}}>
            {children}
        </div>
    );

}

export default RightBodyComponent;