import styles from './ButtonComponent.module.css'


const ButtonComponent = ({text, onClick, style}) => {


    return (
        <button className={styles.button} style={style}
            onClick={(event) => {
                event.stopPropagation()
                onClick()
            }}
        >
            {text}
        </button>

        
    );

}

export default ButtonComponent;