import { useEffect, useState } from 'react';
import styles from './CustomDropdownComponent.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons'


const CustomDropdownComponent = ({ options, forConf, currentChoice, setCurrentChoice, width = 573 }) => {
    const [isOpen, setIsOpen] = useState(false)

    function getIcon(value) {
        switch (value) {
            case "1":
                return "arrow-up-a-z";
            case "2":
                return "arrow-down-z-a";
            case "3":
                return "arrow-up-wide-short";
            case "4":
                return "arrow-down-short-wide";
            case "5":
                return "arrow-up-1-9";
            default:
                return "arrow-down-9-1";
        }
    }

    return (
        <div className={styles.component} tabIndex="0" onBlur={() => setIsOpen(false)}>
            <div className={isOpen ? styles.currentChoiceOpen : styles.currentChoice} onClick={() => setIsOpen(!isOpen)} style={{width}}>
                {currentChoice.text}
                <FontAwesomeIcon className={styles.dropdownIcon} icon={byPrefixAndName.fal[isOpen ? "chevron-up" : "chevron-down"]} />
            </div>

            {isOpen && <div className={styles.openBody} style={{width: width + 27}}>
                {options.map((option) => (
                    <div key={option.value} className={styles.option} onClick={() => {
                        setCurrentChoice(option)
                        setIsOpen(false)
                    }}>
                        {forConf && <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fal[getIcon(option.value)]} />}
                        {option.text}
                    </div>
                ))}
            </div>}

        </div>

    );

}

export default CustomDropdownComponent;