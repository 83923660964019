import { useRef, useContext, useState, useEffect } from 'react';

import { GlobalContext } from "../../contexts/globalContext";
import { ProjectDetailsContext } from '../../contexts/projectDetailsContext';

import styles from './AddParticipantsComponent.module.css'
import InputTextComponent from '../keyboard/InputTextComponent';
import PopupBodyComponent from './common/PopupBodyComponent';
import PopupBaseComponent from './common/PopupBaseComponent';
import ClosePageButtonComponent from '../commons/ClosePageButtonComponent'
import CheckboxComponent from './common/CheckboxComponent';
import LeftBodyComponent from './common/LeftBodyComponent';
import RightBodyComponent from './common/RightBodyComponent';

import membersAdd from '../../graphics/Popups/IconMembersAdd.png'
import FooterButtonsComponent from './common/FooterButtonsComponent';
import CustomDropdownComponent from '../commons/CustomDropdownComponent';
import ElementTableComponent from './common/ElementTableComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons'
import LightButtonComponent from '../commons/LightButtonComponent';

const AddPartipicantsComponent = () => {

    const inputRef = useRef()
    const keyboardRef = useRef()
    const docsRef = useRef()
    const usersRef = useRef()
    const initialRenderDep = useRef(true);
    const initialRenderFunc = useRef(true);

    
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [isOnFocus, setIsOnFocus] = useState(false)
    const [currentRole, setCurrentRole] = useState("MEMBER")

    const [currentChoiceDepartment, setCurrentChoiceDepartment] = useState({
        value: "all",
        text: "All"
    })

    const [currentChoiceFunction, setCurrentChoiceFunction] = useState({
        value: "all",
        text: "All"
    })


    const { language, selectedProject, socketRef, allUsers } = useContext(GlobalContext);
    const { setAddParticipants, setAddGuest, setAddMembers, setAddPartners, setAddVisitors } = useContext(ProjectDetailsContext);

    const [docs, setDocs] = useState()


    const optionsDepartament = [
        {
            value: "all",
            text: "All"
        },
        {
            value: "DEV",
            text: "Development"
        },
        {
            value: "HR",
            text: "HR"
        },
        {
            value: "ADMN",
            text: "Admin"
        }
    ]

    const optionsFunction = [
        {
            value: "all",
            text: "All"
        },
        {
            value: "PROG",
            text: "PROGRAMMER"
        },
        {
            value: "ADMN",
            text: "Admin"
        },
        {
            value: "GST",
            text: "GUEST"
        },
        {
            value: "TEST",
            text: "TEST"
        }
    ]

    useEffect(() => {
        let users = allUsers.filter(user => !selectedProject.participants.some(participant => participant._id === user._id));
        usersRef.current = users

        let result = users.filter(doc => doc.role.includes("MEMBER"));
        let adminDocs = users.filter(doc => doc.role.toLowerCase().includes("administrator"));
        result = [...result, ...adminDocs];

        setDocs(result);
        docsRef.current = result
    }, [])

    const handleClickSelect = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
    };

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(docs.map(doc => doc._id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    function handleParticipantsAdd(){

        socketRef.current.emit("ADD_PARTICIPANTS_TO_PROJECT",
            { projectId: selectedProject._id, participantIds: isCheck }, () =>{
                
            }
        );
        setAddParticipants(false)
    }

    const handleSelectTypeDep = (value) => {
        let result = []
        if(value !== "all"){
            result = docsRef.current.filter(doc => doc.role !== "GUEST" && doc.department?.code.includes(value) );
        } else{
            result = docsRef.current
        }
        if(currentChoiceFunction.value !== "all"){
            result = result.filter(doc => doc.function.code.includes(currentChoiceFunction.value));
        }
        setDocs(result);
        onTextChanged()
    };

    const handleSelectTypeRole = (value) => {
        let result = []
        if(value !== "all"){
            result = docsRef.current.filter(doc => doc.function.code.includes(value));
        } else{
            result = docsRef.current
        }
        if(currentChoiceDepartment.value !== "all"){
            result = result.filter(doc => doc.role !== "GUEST" && doc.department.code.includes(currentChoiceDepartment.value));
        }
        setDocs(result);
        onTextChanged()
    };

    function onTextChanged() {
        let result = []
        if (inputRef.current.value) {
            result = docsRef.current.filter(doc => doc.name?.toLowerCase().includes(inputRef.current.value.toLowerCase()));
        } else {
            result = docsRef.current
        }
        if(currentChoiceDepartment.value !== "all" ){
            result = result.filter(doc => doc.role !== "GUEST" && doc.department.code.includes(currentChoiceDepartment.value));
        }
        if(currentChoiceFunction.value !== "all"){
            result = result.filter(doc => doc.function.code.includes(currentChoiceFunction.value));
        }
        setDocs(result)
    }

    useEffect(() => {
        if (initialRenderDep.current) {
            initialRenderDep.current = false;
            return;
        }
        handleSelectTypeDep(currentChoiceDepartment.value)
    }, [currentChoiceDepartment])

    useEffect(() => {
        if (initialRenderFunc.current) {
            initialRenderFunc.current = false;
            return; 
        }
        handleSelectTypeRole(currentChoiceFunction.value)
    }, [currentChoiceFunction])


    function openPopup(){
        if(currentRole.toLowerCase() === "administrator" || currentRole.toLowerCase() === "member"){
            setAddMembers(true)
        } else if(currentRole.toLowerCase() === "partner"){
            setAddPartners(true)
        } else if(currentRole.toLowerCase() === "guest"){
            setAddGuest(true)
        } else if(currentRole.toLowerCase() === "visitor"){
            setAddVisitors(true)
        }
    }

    useEffect(() => {
        let result = usersRef.current.filter(doc => doc.role.includes(currentRole));
        if(currentRole === "MEMBER") {
            let adminDocs = usersRef.current.filter(doc => doc.role.toLowerCase().includes("administrator"));
            result = [...result, ...adminDocs];
        } else {
            setCurrentChoiceFunction({value: "all", text: "All"})
            setCurrentChoiceDepartment({value: "all", text: "All"})
        }
        inputRef.current.value = ""
        setDocs(result);
        docsRef.current = result
    },[currentRole])


    return (
        <PopupBaseComponent>
            {/* <PopupHeaderComponent text = {language.add_participants['Add Participants']} type="large"/> */}
            <PopupBodyComponent style={{width: 4251, height: 1633}}>
                <ClosePageButtonComponent setClose={() => setAddParticipants(false)} />
                <LeftBodyComponent image={membersAdd} needsCentering={true} title="Add members to project" description="Select one or more members from the list below to add them to the project. The selected members will gain access to project resources and receive all relevant updates and notifications."/>
                <RightBodyComponent width={3040} flex={true}>
                    <div className={styles.selectRole}>
                        <LightButtonComponent style={{marginTop: 500}} onClick={() => setCurrentRole("MEMBER")}>Members</LightButtonComponent>
                        <LightButtonComponent onClick={() => setCurrentRole("PARTNER")}>Partners</LightButtonComponent>
                        <LightButtonComponent onClick={() => setCurrentRole("GUEST")}>Guests</LightButtonComponent>
                        <LightButtonComponent onClick={() => setCurrentRole("VISITOR")}>Visitors</LightButtonComponent>
                    </div>
                    <div className={styles.tablePart}>
                        Participants
                        <div className={styles.select_options}>
                            <InputTextComponent ref={inputRef} fontSize="30" keyboardRef={keyboardRef} value="" setIsOnFocus={setIsOnFocus} styleType='SEARCHPOPUP' placeHolder={language.add_participants.Search} onTextChanged={onTextChanged}/>
                            {currentRole.toLowerCase() === "member" && 
                            <CustomDropdownComponent options={optionsFunction} currentChoice={currentChoiceFunction} setCurrentChoice={setCurrentChoiceFunction} width={573}/>}
                            {currentRole.toLowerCase() === "member" && 
                            <CustomDropdownComponent options={optionsDepartament} currentChoice={currentChoiceDepartment} setCurrentChoice={setCurrentChoiceDepartment} width={573}/>}
                            <FontAwesomeIcon className={styles.icon} icon={byPrefixAndName.fal['magnifying-glass']} style={{color: isOnFocus ? "#33FFC8" : ""}}/>
                        </div> 
                        <div className={styles.tableHeader}>
                            <CheckboxComponent text={language.add_participants['Select All']} checked={isCheckAll} onChange={handleSelectAll} />
                            {currentRole.toLowerCase() === "member" && <div className={styles.centerElement}>Function</div>}
                            {currentRole.toLowerCase() === "member" && <div className={styles.rightElement}>Department</div>}
                        </div>
                        <ElementTableComponent docs={docs} isCheck={isCheck} isMember={currentRole.toLowerCase() === "member"} onChange={handleClickSelect} style={{ paddingLeft: 0, marginTop: 5 }}/>
                        <div className={styles.circle} onClick={() => openPopup()}> + </div>
                    </div>
                    
                    <FooterButtonsComponent lightText={"Add"} lightClick={handleParticipantsAdd} darkClick={setAddParticipants}/>
                </RightBodyComponent>
                
            </PopupBodyComponent>

        </PopupBaseComponent>
    );

}

export default AddPartipicantsComponent;