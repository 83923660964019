

import { useContext, useEffect, useRef } from 'react';
import { GlobalContext } from "../contexts/globalContext"
import io from "socket.io-client"


const SocketManager = () => {
    const { selectedTopicIdRef, selectedParentForFiles, setTopicFiles, devices, socketRef, setAllUsers, setDetailsUpcomingConference, setProjectsList, currentUser, setNotificationUpcomingConferences, setHistoryConferences, selectedProject, setSelectedProject, statusProjectList, setDepartmentList, setFunctionList} = useContext(GlobalContext);
    const devicesRef = useRef();
    const selectedProjectIDRef = useRef();


    useEffect(() => {
        devicesRef.current = devices;
    }, [devices])

    useEffect(() => {
        selectedProjectIDRef.current = selectedProject?._id;
    }, [selectedProject])

    useEffect(() => {
        socketRef.current = io.connect(process.env.REACT_APP_DOCS_URL, {
            secure: true,
            reconnect: true,
            rejectUnauthorized: true,
            query: { token: currentUser.token }
        })
        
        socketRef.current.on('connect', function () {
        
            socketRef.current.emit("GET_ALL_USERS", (users) => {
                setAllUsers(users)
            });
        
            socketRef.current.emit('GET_ALL_PROJECTS', currentUser._id, null, true, (projects) => {
                setProjectsList(projects)
            });

            // active = true, inactive = false, all = null

            socketRef.current.on('REFRESH_PROJECTS', async () => {
                socketRef.current.emit('GET_ALL_PROJECTS', currentUser._id, null, statusProjectList, (projects) => {
                    setProjectsList(projects)
                });

                socketRef.current.emit("GET_UPCOMING_CONFERENCES", (conferences) => {
                    setNotificationUpcomingConferences(conferences)
                });
            })

            socketRef.current.on('REFRESH_PROJECT', async () => {
                socketRef.current.emit("GET_PROJECT_BY_ID_PROJECTS", {projectId: selectedProjectIDRef.current, selectedTopicId: selectedTopicIdRef.current}, (project, conference, topicFiles) => {
                    setSelectedProject(project)
                    setDetailsUpcomingConference(conference)
                    setTopicFiles(topicFiles)
        
                    if (project.topics.length > 0 && !selectedTopicIdRef.current)
                        selectedTopicIdRef.current = project.topics[0]._id;
                    
                })

                // socketRef.current.emit("GET_TOPIC_USER_DOCUMENTS", {parentId: selectedParentForFiles}, (docs) => {
                //     setTopicFiles(docs)
                // })
            })

            socketRef.current.emit("GET_UPCOMING_CONFERENCES", (conferences) => {
                setNotificationUpcomingConferences(conferences)
            });

            socketRef.current.emit("GET_DONE_CONFERENCES", (conferences) => {
                setHistoryConferences(conferences)
            });
        
            // socketRef.current.emit("GET_ALL_CONFERENCES", (conferences) => {
            //     // console.log(conferences)
            // });

            socketRef.current.emit("GET_ALL_DEPARTMENTS",  (callback) => {
                setDepartmentList(callback)
            });
    
            socketRef.current.emit("GET_ALL_FUNCTIONS", (callback) => {
                setFunctionList(callback)
            });
        
        }); 
    }, [])

    return (
        <> </>
    );
}

export default SocketManager;