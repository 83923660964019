import { forwardRef } from 'react';
import styles from './MoreButtonComponent.module.css'


const MoreButtonComponent = forwardRef(({ clicked, setClicked, id }, ref) => {

    return (
        <button ref={ref} className={clicked.shown && clicked.id === id ? styles.componentClicked : styles.component} 
        onClick={(e) => {
            setClicked()
            e.stopPropagation()
        }}>
            <div className={clicked.shown && clicked.id === id ? styles.dotsClicked : styles.dots}>...</div>
        </button>
    );
})

export default MoreButtonComponent;