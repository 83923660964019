import { useContext, useEffect, useRef, useState } from 'react';

import styles from './ConferenceScreenComponent.module.css'

import { GlobalContext } from "../../contexts/globalContext";
import ClosePageButtonComponent from '../commons/ClosePageButtonComponent';

import Peer from "simple-peer";
import VideoStreamComponent from './VideoStreamComponent';


const ConferenceScreenComponent = () => {

    const { conferenceScreen, setConferenceScreen, socketRef, currentUser, currentConference } = useContext(GlobalContext)

    const [streams, setStreams] = useState([]);
    const [myStream, setMyStream] = useState();
    const streamRef = useRef([]);
    const currentUserRef = useRef([]);

    const [peers, setPeers] = useState([]);
    const peersRef = useRef([]);
    const VideoComponentSize = [300, 170, parseInt(process.env.REACT_APP_WIDTH) - 200];


    useEffect(() => {
        currentUserRef.current = currentUser;
    }, [currentUser])


    useEffect(() => {
        console.log("streams");
        console.log(streams);
    }, [streams])

    useEffect(() => {
        navigator.mediaDevices.getUserMedia({
            video: {
                width: { ideal: 1920 },
                height: { ideal: 1080 },
                facingMode: "environment"
            }, audio: true
        }).then((stream) => {
            console.log("creeam listnersii");
            socketRef.current.emit('JOIN_ROOM', { roomID: currentConference, name: currentUser.name })

            setMyStream({ stream, "userId": socketRef.current.id, "is_mine": true, name: currentUser.name });
            const track = stream.getVideoTracks()[0];
            const constraints = track.getConstraints();

            socketRef.current.on("ALL_USERS", users => {
                const peers = [];
                console.log("users", users)
                users.forEach(user => {
                    const peer = createPeer(user.socket_id, socketRef.current.id, stream);
                    peersRef.current.push({
                        peerID: user.socket_id,
                        peer,
                    })

                    peer.on("stream", stream => {
                        SetStreamsAndRecalculatePosition({ stream, "userId": user.socket_id, "is_mine": false, name: user.name });

                    })

                    peers.push(peer);
                })
                setPeers(peers);
            })            

            socketRef.current.on("USER_JOINED", payload => {
                const peer = addPeer(payload.signal, payload.callerID, stream);
                peersRef.current.push({
                    peerID: payload.callerID,
                    peer,
                })

                peer.on("stream", stream => {
                    SetStreamsAndRecalculatePosition({ stream, "userId": payload.callerID, "is_mine": false, name: payload.name });
                })

                setPeers(peers => [...peers, peer]);
            });

            socketRef.current.on("RECEIVING_RETURNED_SIGNAL", payload => {
                const item = peersRef.current.find(p => p.peerID === payload.id);
                item.peer.signal(payload.signal);
            });

            socketRef.current.on('USER_DISCONECTED', (userId) => {
                if (userId == socketRef.current.id) {
                    streamRef.current.forEach(stream => {
                        stream.stream.getTracks().forEach((track) => {
                            track.stop();
                        });
                    })

                    peersRef.current.forEach(peer => {
                        peer.peer.destroy();
                    })


                    setStreams([]);

                    peersRef.current = [];

                    streamRef.current = [];


                } else {
                    const otherStream = streamRef.current.find(pr => pr.userId === userId);
                    if (otherStream && otherStream.stream) {
                        otherStream.stream.getTracks().forEach((track) => {
                            track.stop();
                        });
                    }


                    const peer = peersRef.current.find(p => p.peerID === userId);
                    if (peer && peer.peer)
                        peer.peer.destroy();

                    setStreams(streams => streams.filter(stre => stre.userId !== userId));

                    peersRef.current = peersRef.current.filter(pr => pr.peerID !== userId);

                    streamRef.current = streamRef.current.filter(pr => pr.userId !== userId);
                    SetStreamsAndRecalculatePosition();
                }


            });
        }).catch((error) => {
            console.error("Error accessing media devices.", error);
        });

        return () => {
            socketRef.current.off('USER_JOINED');
            socketRef.current.off('RECEIVING_RETURNED_SIGNAL');
            socketRef.current.off('USER_DISCONECTED');
            socketRef.current.off('ALL_USERS');
        }
    }, [])



    function SetStreamsAndRecalculatePosition(new_stream_obj) {
        console.log('func', new_stream_obj)
        // const screenHeight = parseInt(process.env.REACT_APP_HEIGHT);
        if (new_stream_obj != null)
            streamRef.current.push(new_stream_obj);
        // var stream_no = streamRef.current.length;

        // const total_height = stream_no * VideoComponentSize[1] + (stream_no - 1) * 20;

        // var first_y = (screenHeight - total_height) / 2;

        // streamRef.current.forEach((stream_aux, index) => {
        //     stream_aux.X = VideoComponentSize[2];
        //     stream_aux.Y = first_y + (index * VideoComponentSize[1]) + 20 * (index - 1);
        //     stream_aux.WIDTH = VideoComponentSize[0];
        //     stream_aux.HEIGHT = VideoComponentSize[1];
        // });

        setStreams([...streamRef.current]);
    }

    function createPeer(userToSignal, callerID, stream) {
        const peer = new Peer({
            initiator: true,
            trickle: false,
            stream,
            config: {
                iceServers: [
                    {
                        urls: [
                            'stun:stun.l.google.com:19302',
                            'stun:global.stun.twilio.com:3478'
                        ]
                    }
                ]
            }
        });
        console.log("create peer", peer)

        peer.on("signal", signal => {
            socketRef.current.emit("SENDING_SIGNAL", { userToSignal, callerID, signal, name: currentUserRef.current.name })
        })

        return peer;
    }

    function addPeer(incomingSignal, callerID, stream) {
        const peer = new Peer({
            initiator: false,
            trickle: false,
            stream,
            config: {
                iceServers: [
                    {
                        urls: [
                            'stun:stun.l.google.com:19302',
                            'stun:global.stun.twilio.com:3478'
                        ]
                    }
                ]
            }
        })

        peer.on("signal", signal => {
            socketRef.current.emit("RETURNING_SIGNAL", { signal, callerID })
        })

        peer.signal(incomingSignal);

        console.log("add peer", peer)

        return peer;
    }

    return (
        <div className={styles.component}>
            <ClosePageButtonComponent setClose={() => {
                setConferenceScreen(false)
                socketRef.current.emit("LEAVE_ROOM", currentConference);
            }} />
            {streams && streams.map((stream, index) => (
                <VideoStreamComponent key={stream.userId} stream={stream.stream} name={stream.name} is_mine={stream.is_mine}/>
                
            ))}

        </div>
    );
}

export default ConferenceScreenComponent;