import { useRef, useContext, useState, useEffect } from 'react';

import { GlobalContext } from "../../contexts/globalContext";

import styles from './HeaderComponent.module.css'


import InitialsCircleComponent from './InitialsCircleComponent';

import imageBack from '../../graphics/menu-culori.png'

import HeaderDetailsComponent from '../projectDetails/PD_HeaderComponent';
import PL_HeaderComponent from '../projectList/PL_HeaderComponent';


const HeaderComponent = ({ currentProjectList, setCurrentProjectList, setSeeNotification, seeNotification}) => {


    var [date, setDate] = useState(new Date());

    const {seeUserDetails, currentUser, setSeeUserDetails } = useContext(GlobalContext);


    useEffect(() => {
        var timer = setInterval(() => setDate(new Date()), 1000)
        return function cleanup() {
            clearInterval(timer)
        }
    });


    return (

        <div className={styles.header}>
            <img src={imageBack} className={styles.backgroundImg} alt={"fundal"} />
            <InitialsCircleComponent name={currentUser?.name} onClick={() => setSeeUserDetails(!seeUserDetails)} />


            {currentProjectList && <PL_HeaderComponent currentProjectList={currentProjectList} setCurrentProjectList={setCurrentProjectList} setSeeNotification={setSeeNotification} seeNotification={seeNotification}/>}

            {!currentProjectList && <HeaderDetailsComponent setSeeNotification={setSeeNotification} seeNotification={seeNotification}/>}

            <div className={styles.date}>
                {date.toLocaleDateString('en-RO', { weekday: 'short' })}. {date.toLocaleDateString('en-RO', { day: '2-digit' })} {date.toLocaleDateString('en-RO', { month: 'short' })}. {date.toLocaleDateString('en-RO', { year: 'numeric' })}
            </div>
            <div className={styles.time}>
                {date.toLocaleTimeString('en-RO', { hour12: false, hour: '2-digit', minute: '2-digit' })}
            </div>
        </div>


    );

}

export default HeaderComponent;