import { useContext, useState, useEffect } from 'react';

import { GlobalContext } from "../../../contexts/globalContext";

import PD_BaseListComponent from '../PD_BaseListComponent';
import PD_ConferenceComponent from './PD_ConferenceComponent';


const ConferenceListComponent = () => {

    const { language, selectedProject } = useContext(GlobalContext);


    const [sortedConferences, setSortedConferences] = useState(selectedProject.conferences.sort((a, b) => new Date(b.scheduleDate) - new Date(a.scheduleDate)))

    useEffect(() => {
        setSortedConferences(selectedProject.conferences.sort((a, b) => new Date(b.scheduleDate) - new Date(a.scheduleDate)))
    },[selectedProject.conferences])

   

    return (
        
        <PD_BaseListComponent 
            title={language.project_details.Conferences}
            list = {sortedConferences?.map((conference) => (
                <PD_ConferenceComponent key = {conference._id} conference={conference} />
            ))}
        />
        
    );

}

export default ConferenceListComponent;