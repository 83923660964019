import { useRef, useState, useEffect, forwardRef } from 'react';
import Keyboard from 'react-simple-keyboard';

import 'react-simple-keyboard/build/css/index.css';
import './KeyboardComponent.css';

const KeyboardComponent = forwardRef((props, ref) => {
    const currentInputForKeyboard = useRef();
    const compRef = useRef();

    const [layoutName, setLayoutName] = useState("default");
    const [inputName, setInputName] = useState("default");

    const [clickedButton, setClickedButton] = useState(null);

    // const [width, setWidth] = useState(0);
    // const [height, setHeight] = useState(0);


    useEffect(() => {
        ref.current.setInputElement = (element) => {
            currentInputForKeyboard.current = element;

            setInputName(currentInputForKeyboard.current.getAttribute("name"));

            // setWidth(Number(props.width));
            // setHeight(Number(props.height));

            if (props.onVisible) {
                props.onVisible();
            }

            if (currentInputForKeyboard.current.keyboardOpenAt) {
                let splt = currentInputForKeyboard.current.keyboardOpenAt.split(",");
                let xx = Number(splt[0]);
                let yy = Number(splt[1]);
                compRef.current.style.transform = `matrix(1, 0, 0, 1, ${xx},  ${yy})`;
            }
        }

        ref.current.hideIt = () => {
            // setWidth(0);
            // setHeight(0);
            if (props.onClose) {
                props.onClose();
            }
        }

        if (props.visible) {
            // setWidth(Number(props.width));
            // setHeight(Number(props.height));
        }
    }, [ref.current])

    useEffect(() => {
        if (inputName && inputName !== "default") {
            ref.current.setInput(currentInputForKeyboard.current.value);
        }
    }, [inputName])


    const onKeyPress = button => {
        if (button === "{shift}" || button === "{lock}") handleShift();
        setClickedButton(button)
    };

    const handleShift = () => {
        setLayoutName((layoutName === "default") ? "shift" : "default");
    };

    const onChangeAll = inputs => {
        if(currentInputForKeyboard.current){
            currentInputForKeyboard.current.value = inputs[currentInputForKeyboard.current.getAttribute("name")];
            currentInputForKeyboard.current.onTextChanged();
        } 
        
    };



    return (

        <div ref={compRef}  style={{ fontSize:"32px", position:"absolute", transform: `matrix(${props.s}, 0, 0, ${props.s}, ${props.x}, ${props.y})`, width: props.width, height: props.height}}>
            <Keyboard
                keyboardRef={r => (ref.current = r)}
                layoutName={layoutName}
                inputName={inputName}
                onKeyPress={onKeyPress}
                onKeyReleased={() => setClickedButton(null)}
                onChangeAll={onChangeAll}
                theme="hg-theme-default hg-layout-default myTheme"
                buttonTheme={[
                    {
                        class: "hg-conf",
                        buttons: "{space} {bksp} .com {tab} {lock} {shift} {enter} ` 1 2 3 4 5 6 7 8 9 0 - = q w e r t y u i o p [ ] \\ a s d f g h j k l ; '  z x c v b n m , . / @ A B C D E F G H I J K L M N O P Q R S T U W V X Y Z < > ? ~ ! # $ % ^ & * ( ) { } | + : _"
                    },
                    {
                        class: "hg-conf",
                        buttons: '"'
                    },
                    {
                        class: "hg-conf-com",
                        buttons: '.com'
                    },
                    {
                        class: "hg-conf-tab",
                        buttons: '{tab}'
                    },
                    {
                        class: "hg-conf-caps",
                        buttons: '{lock}'
                    },
                    {
                        class: "hg-conf-shift",
                        buttons: '{shift}'
                    },
                    {
                        class: "hg-conf-enter",
                        buttons: '{enter}'
                    },
                    {
                        class: "hg-conf-bksp",
                        buttons: '{bksp}'
                    },
                    {
                        class: clickedButton ? "hg-conf-click" : '',
                        buttons: clickedButton ? clickedButton : ''
                    }
                ]}
            />
        </div>

    );
})


export default KeyboardComponent;