import styles from './CheckboxComponent.module.css'
import Form from 'react-bootstrap/Form';
import '../../projectDetails/custom.css'

const CheckboxComponent = ({checked, onChange, text, id, style}) => {

    return (
        <div className={styles.component}> 
        <Form>
            <Form.Check // prettier-ignore
                type='checkbox'
                label={text}
                checked={checked}
                onChange={onChange}
                className='custom-checkbox'
                id={id}
            />
        </Form>
        </div>
        
    );

}

export default CheckboxComponent;