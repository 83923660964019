import { useContext } from 'react';

import { GlobalContext } from "../contexts/globalContext";

import styles from './NotificationComponent.module.css'
import historyVerticalLine from '../graphics/linie timeline istoric.svg'
import upcomingLine from '../graphics/liniute.svg'
import historyCircle from '../graphics/cerc-timeline.svg'

import { GetMaxZIndex } from '../utils/UtilitaryFunctions'

const NotificationComponent = () => {

    const { notificationUpcomingConferences, historyConferences, language } = useContext(GlobalContext);

    function getDate(date) {
        return new Date(date)
    }


    return (

        <div className={styles.notification} style={{zIndex: GetMaxZIndex() + 1}}>
            <div className={styles.conferences_line}>
                <div className={styles.line_title}>{language.notification.Upcoming}</div>
                {notificationUpcomingConferences.map((upcomingConference) =>
                    <div key={upcomingConference._id} className={styles.conference_rectangle}
                        style={{
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            boxShadow: "0px 8px 20px #000000",
                            border: "1px solid #484060"
                        }}
                    >
                        <div className={styles.upcoming_date}>
                            {getDate(upcomingConference.scheduleDate).toLocaleDateString('en-RO', { month: 'short' }).toUpperCase()}. {getDate(upcomingConference.scheduleDate).toLocaleDateString('en-RO', { day: '2-digit' })}
                            <div className={styles.hourConf}>
                            {getDate(upcomingConference.scheduleDate).toLocaleTimeString('en-RO', { hour12: false, hour: '2-digit', minute: '2-digit' })}
                            </div>
                        </div>
                        <div className={styles.upcoming_name}><b>{upcomingConference.name}</b>
                        <div style={{marginTop: '15px'}}>  {upcomingConference.project.name}</div>
                        </div>

                        <img src={historyCircle} className={styles.historyCircle} alt="Upcoming Line" />
                    </div>)
                }
            </div>
            <img src={upcomingLine} alt="Upcoming Line" />
            <div className={styles.historyLine}>
                <div className={styles.line_title} style={{ color: "white", paddingTop: 20 }}>{language.notification.History}</div>
                {historyConferences.map((historyConference) =>
                    <div key={historyConference._id} className={styles.conference_rectangle} style={{ color: "white", fontSize: 32, marginTop: 0, height: "100%" }}>
                        <div className={styles.upcoming_date} style={{ textAlign: "right", paddingTop: 65, paddingRight: 30, width: 185 }}><b>05.02.2024 16:30</b></div>
                        <img src={historyVerticalLine} className={styles.historyVerticalLine} alt={"linie timeline istoric"} />
                        <div className={styles.upcoming_name} style={{ paddingTop: 65 }}><b>{historyConference.name}</b><br />  Nexus Nova</div>
                    </div>)
                }
            </div>

        </div>


    );

}

export default NotificationComponent;