import { useContext } from 'react';
import styles from './StatusButtonComponent.module.css'
import { GlobalContext } from '../../contexts/globalContext';

const StatusButtonComponent = ({ text, buttonStatus, onClick }) => {

    const {statusProjectList} = useContext(GlobalContext);

    return (
        <div className={statusProjectList === buttonStatus ? styles.status_button_selected: styles.status_button} onClick={onClick}>
            {text}
        </div>
    );

}

export default StatusButtonComponent;