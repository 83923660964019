import { useContext, useEffect, useState } from 'react';

import { GlobalContext } from "../../../contexts/globalContext";
import { ProjectDetailsContext } from '../../../contexts/projectDetailsContext';

import PD_BaseListComponent from '../PD_BaseListComponent';
import PD_ParticipantComponent from './PD_ParticipantComponent';
import ButtonComponent from '../../commons/ButtonComponent';
import PD_ListFooterButtonsComponent from '../PD_ListFooterButtonsComponent';



const ParticipantListComponent = () => {
    
    const { language, currentUser, selectedProject } = useContext(GlobalContext);
    const { setAddParticipants, setAddGuest} = useContext(ProjectDetailsContext);


    const [sortedParticipants, setSortedParticipants] = useState(selectedProject.participants.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    }))

    useEffect(() => {
        const sort = selectedProject.participants.sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        });
        setSortedParticipants(sort)
    },[selectedProject.participants])

    return (
        <PD_BaseListComponent 
            title={language.project_details.Participants}
            list = {sortedParticipants?.map((participant) => (
                <PD_ParticipantComponent key={participant._id} participant={participant} />
            ))}
            // buttons = {(currentUser?.function.code === "ADMN" || currentUser?.role.toLowerCase() === "administrator") && <PD_ListFooterButtonsComponent>
            //     <ButtonComponent text={language.project_details['Add guests']} style={{ marginTop:50}} onClick={() => setAddGuest(true)}/>
            //     <ButtonComponent text={language.project_details['Add participants']} style={{ marginTop:10}} onClick={() => setAddParticipants(true)}/>
            // </PD_ListFooterButtonsComponent>}
        />

    );

}

export default ParticipantListComponent;