import { useState, useContext } from 'react';
import styles from './AddNewDetailsComponent.module.css'

import { ProjectDetailsContext } from '../../contexts/projectDetailsContext';
import { GlobalContext } from '../../contexts/globalContext';

import AddButtonComponent from './AddButtonComponent';


const AddNewDetailsComponent = () => {
    const [seeExtension, setSeeExtenstion] = useState()

    const { currentUser } = useContext(GlobalContext);
    const { setAddFiles, setAddParticipants, setAddGuest, setAddTopic, setCreateConference } = useContext(ProjectDetailsContext);


    return (
        <>
            <div className={seeExtension === false ? styles.extensionReversed : (seeExtension === true ? styles.extension : "")} >
                {(currentUser?.function.code === "ADMN"|| currentUser?.role.toLowerCase() === "administrator") && 
                    <AddButtonComponent onClick={() => setCreateConference(true)} icon='users-rectangle' text='New Conference'/>
                }
                <AddButtonComponent onClick={() => setAddTopic(true)} icon='book-bookmark' text='New Topic'/>
                {(currentUser?.function.code === "ADMN"|| currentUser?.role.toLowerCase() === "administrator") && <>
                    <AddButtonComponent onClick={() => setAddParticipants(true)} icon='user' text='Add Participant'/>
                    {/* <AddButtonComponent onClick={() => setAddGuest(true)} icon='id-card-clip' text='Add Guest'/> */}
                    <AddButtonComponent onClick={() => setAddFiles(true)} icon='file-plus' text='Add File'/>
                </>}
            </div>
            <div className={seeExtension === false ? styles.circleClickedOff : (seeExtension === true ? styles.circleClickedOn : styles.circle)} onClick={() => setSeeExtenstion(!seeExtension)}> + </div>
        </>


    );

}

export default AddNewDetailsComponent;