

import styles from './PopupBaseComponent.module.css'
import { GetMaxZIndex } from '../../../utils/UtilitaryFunctions'


const PopupBaseComponent = ({children}) => {
    
    return (
        <div className={styles.component} style={{zIndex: GetMaxZIndex() + 1}}>
            {children}
        </div>
    );

}

export default PopupBaseComponent;