import { useContext, useRef, useEffect } from 'react';

import styles from './MoreMenuComponent.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-fe6b9b7683/icons'

import { GlobalContext } from '../../contexts/globalContext';




const MoreMenuComponent = ({ moreClicked, setMoreClicked }) => {

    const ref = useRef();

    useEffect(() => {
        ref.current.focus();
    }, []);

    const { isConf, clickEdit, clickClone, clickRemove, x, y } = moreClicked

    const { language } = useContext(GlobalContext);

    const oxDivider = window.innerWidth / process.env.REACT_APP_WIDTH;
    const oyDivider = window.innerHeight / process.env.REACT_APP_HEIGHT;

    return (
        <div ref={ref} className={styles.component} style={{ transform: `matrix(1, 0, 0, 1, ${x / oxDivider + 124}, ${y / oyDivider})` }} tabIndex="0" 
        onBlur={() => {
            setMoreClicked({})
        }}>

            {clickEdit && <div className={styles.edit} onClick={clickEdit}>
                <FontAwesomeIcon icon={byPrefixAndName.fal['pen-to-square']} />
                <div className={styles.buttonText}>{language.more_menu.Edit.toUpperCase()}</div>
            </div>}
            {isConf && <div className={styles.edit} onClick={clickClone}>
                <FontAwesomeIcon icon={byPrefixAndName.fal['clone']} />
                <div className={styles.buttonText}>{language.more_menu.Clone.toUpperCase()}</div>
            </div>}
            <div className={styles.remove} onClick={clickRemove}>
                <FontAwesomeIcon icon={byPrefixAndName.fal['square-minus']} />
                <div className={styles.buttonText}>{language.more_menu.Remove.toUpperCase()}</div>
            </div>
        </div>

    );

}

export default MoreMenuComponent;