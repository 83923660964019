import styles from './DarkButtonComponent.module.css'


const DarkButtonComponent = ({children, onClick, style}) => {


    return (
        <button className={styles.component} style={style}
            onClick={(event) => {
                event.stopPropagation()
                onClick()
            }}
        >
            {children}
        </button>

        
    );

}

export default DarkButtonComponent;