import { useContext, useRef, useState} from 'react';

import { GlobalContext } from "../contexts/globalContext";

import styles from './ProjectsListComponent.module.css'

import ProjectDetailsComponent from './ProjectDetailsComponent';
import HeaderComponent from './commons/HeaderComponent';
import ProjectElementComponent from './projectList/ProjectElementComponent';
import SocketManager from '../utils/SocketManager';
import NotificationComponent from './NotificationComponent';
import UserDetailsComponent from './UserDetailsComponent';
import { ProjectDetailsProvider } from "../contexts/projectDetailsContext"

import video from '../graphics/big wave fundal loop_6.mp4'


// import fundal from '../graphics/'

const ProjectsListComponent = () => {
    const ref = useRef()

    const [currentProjectList, setCurrentProjectList] = useState([])
    const [seeNotification, setSeeNotification] = useState(false)

    const { selectedProject, seeUserDetails} = useContext(GlobalContext);


    return (
        <>
            <SocketManager />
            {!selectedProject && <div className={styles.full_screen_component} >
                <video id="background-video" className={styles.background} autoPlay loop playsInline muted>
                    <source src={video} type="video/mp4"/>
                </video>
                <HeaderComponent currentProjectList={currentProjectList} setCurrentProjectList={setCurrentProjectList} setSeeNotification={setSeeNotification} seeNotification={seeNotification}/>
                {seeNotification && <NotificationComponent/>}
                {currentProjectList.length < 5 ?
                    <div className={styles.list}>
                        {currentProjectList.map((project, index) => (
                            <ProjectElementComponent project={project} key={project._id} index={index} seeNotification={seeNotification}/>
                        ))}
                    </div>
                    : 
                    <div ref={ref} className={styles.overflowing_list}>
                        {currentProjectList.map((project, index) => (
                            <ProjectElementComponent project={project} key={project._id} index={index} seeNotification={seeNotification}/>
                        ))}
                    </div>

                }
                
            </div>}
            {seeUserDetails && <UserDetailsComponent/>}
            {!selectedProject && seeNotification && <div className={styles.bottomShadow}/>}
            <div className={styles.leftShadow}/>
            <div className={styles.rightShadow}/>
            {selectedProject && <ProjectDetailsProvider><ProjectDetailsComponent /></ProjectDetailsProvider>}
        </>
    );

}

export default ProjectsListComponent;