import { useRef, useEffect, forwardRef } from 'react';
import styles from './InputTextComponent.module.css'
import uuid from 'react-uuid';


const InputTextComponent = forwardRef(( props, ref) => {

    const keyboardRef = useRef();

    useEffect(() => {

        // console.log(props)

        ref.current.setAttribute("name", uuid());
        ref.current.value = props.value;

        ref.current.onTextChanged = () => {
            if (props.onTextChanged)
                props.onTextChanged();
        }

        ref.current.keyboardOpenAt = props.keyboardOpenAt;
    }, [])

    useEffect(() => {
        keyboardRef.current = props.keyboardRef.current;
        if (keyboardRef.current) {
            keyboardRef.current.setInputElement(ref.current);

        }
    }, [props.keyboardRef.current])

    const onChangeInput = event => {
        if (keyboardRef.current)
            keyboardRef.current.setInput(event.target.value);
        ref.current.onTextChanged();
    };

    const getClassName = (styleType) => {
        switch (styleType) {
            case 'SRCH':
                return styles.search_input;
            case 'EDT':
                return styles.editName;
            case 'ADD':
                return styles.inputAddBox;
            case 'POPUP':
                return styles.inputPopup;
            case 'SEARCHPOPUP':
                return styles.searchPopup;
            default:
                return styles.input; 
        }
    };
    

    return (
        <div style={{ position: props.position, transform: `matrix(1, 0, 0, 1, ${props.x}, ${props.y})`, height: props.height }}>
            <input
                ref={ref}
                // switch dupa style type
                className={getClassName(props.styleType)}
                placeholder={props.placeHolder}
                type={props.type ? props.type : ""}
                onFocus={() => {
                    if (keyboardRef.current) {
                        keyboardRef.current.setInputElement(ref.current);
                    }
                    if(props.setIsOnFocus)
                        props.setIsOnFocus(true)
                    if(props.setIndex)
                        props.setIndex(props.index)
                }}
                onBlur={() => {
                    if(props.setIsOnFocus)
                        props.setIsOnFocus(false);
                    // if(props.setIndex)
                    //     props.setIndex(-1)
                }}
                onChange={onChangeInput}
                style={{ fontSize: props.fontSize ? props.fontSize : (props.search === "true" ? 40 : 60), width: props.width }}
            />
        </div>
    );
})

export default InputTextComponent;

