import style from './TagComponent.module.css'


const TagComponent = ({status, text}) => {
    const bgColor = status === "active" ? "rgb(255, 201, 21, 0.2)" : (status === "inactive" ? "rgb(198, 198, 198, 0.2)" : "rgb(51, 255, 200, 0.2)")
    const color = status === "active" ? "#FFC915" : (status === "inactive" ? "#C6C6C6" : "#33FFC8")
    
    return (
        <div className={style.tag} style={{backgroundColor: bgColor, color: color}}>
            <div className={style.tagText}>{text.toUpperCase()}</div>
        </div>
    );

}

export default TagComponent;