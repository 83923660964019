import CheckboxComponent from './CheckboxComponent';
import styles from './ElementTableComponent.module.css'



const ElementTableComponent = ({ docs, isCheck, onChange, style, isFiles = false, isMember = false }) => {

    return (
        <div className={styles.component}>
            {docs && !isFiles && docs.map((doc) => (
                <div className={styles.element} key={doc._id}>
                    <CheckboxComponent style={style} checked={isCheck.includes(doc._id)} onChange={onChange} id={doc._id} text={doc.name} />
                    {isMember && <div className={styles.centerElement}>{doc.function?.name}</div>}
                    {isMember && <div className={styles.rightElement}>{doc.department?.name}</div>}
                </div>
            ))}
            {docs && isFiles && docs.map((doc) => (
                <div className={styles.element} key={doc._id}>
                    <CheckboxComponent style={style} checked={isCheck.includes(doc._id)} onChange={onChange} id={doc._id} text={doc.name} />
                    <div className={styles.typeElement}>{doc.type}</div>
                </div>
            ))}
        </div>
    );

}

export default ElementTableComponent;