import { useContext, useEffect, useRef, useState } from 'react';

import styles from './VideoStreamComponent.module.css'

const VideoStreamComponent = (props) => {

    const videoRef = useRef(null);

    const analyser = useRef();

    useEffect(() => {
        videoRef.current.srcObject = props.stream;

        if (props.is_mine) {
            videoRef.current.muted = true;
        } else {
            //console.log(props.stream);
            videoRef.current.muted = true;

            var audioCtx = new AudioContext();
            analyser.current = audioCtx.createAnalyser();

            var source = audioCtx.createMediaStreamSource(props.stream);

            source.connect(analyser.current);
            analyser.current.connect(audioCtx.destination);

            analyser.current.fftSize = 512;

        }
    }, [])

    return (
        <div className={styles.component} >
            <video className={styles.video} ref={videoRef} playsInline autoPlay />
        </div>
    );

}

export default VideoStreamComponent;